import React from 'react'
import ContactBanner from './ContactBanner'
import Contact from './Contact'


function ContactErda() {
  return (
	<div>
	  <ContactBanner/>
	  <Contact/>
	 
	</div>
  )
}

export default ContactErda
