import React from 'react'
import Diaspora from '../diaspora-affairs/Diaspora'



function DiasporaAffairs() {
  return (
	<div>
	  <Diaspora/>
	</div>
  )
}

export default DiasporaAffairs
