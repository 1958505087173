import React from 'react'
import EventBanner from './EventBanner'
import EventTraining from './EventTraining'

function EventsTraining() {
  return (
	<div>
	  <EventBanner/>
	  <EventTraining/>
	</div>
  )
}

export default EventsTraining
